import React from 'react';
import { Web3Button, Web3NetworkSwitch } from '@web3modal/react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { useEffect, useState } from "react";
import { Button, Container, Dropdown, Navbar, Spinner, Table } from "react-bootstrap";
import ReactTimeAgo from "react-time-ago";
import { useAccount, useBalance, useContractRead, useContractWrite, useNetwork, usePrepareContractWrite } from 'wagmi';
import "./App.css";
import MessageList from "./components/MessageListComponent";
import CreatePostModal from "./components/modals/CreatePostModal";
import RegisterModal from "./components/modals/RegisterModal";
import { supportedVsCurrencies } from './ethData/coingecko';
import { abi, chainIdToContractAddress } from "./ethData/contract";
import { ReactComponent as Hamster } from './hamster.svg';
import AboutModal from './components/modals/AboutModal';
import { useCookies } from 'react-cookie';
import { formatEther } from 'viem';

TimeAgo.addDefaultLocale(en);

interface IAppContext {
  minBidIncrement?: bigint;
  refreshMessages?: boolean;
  setRefreshMessages?: React.Dispatch<React.SetStateAction<boolean>>;
  refetchUsername?: () => void;
}

export const AppContext = React.createContext<IAppContext>({});

function App() {
  // Global context
  const [minBidIncrement, setMinBidIncrement] = useState<bigint>();
  const [refreshMessages, setRefreshMessages] = useState<boolean>(false);
  
  const { chain, chains } = useNetwork(); // connected chain, allowed chains
  const [worldCurrencyToCoinPrice, setWorldCurrencyToCoinPrice] = useState<{ [x:string]: number }>();

  const [showPostModal, setShowPostModal] = useState<boolean>(false);
  const [showAboutModal, setShowAboutModal] = useState<boolean>(false);
  const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
  const [postBid, setPostBid] = useState<string>();
  const [minBidInEth, setMinBidInEth] = useState<string>();
  const [bidResetDate, setBidResetDate] = useState<Date>();

  const [contractOwner, setContractOwner] = useState<string>();
  const [userIsOwner, setUserIsOwner] = useState<boolean>(false);
  const [username, setUsername] = useState<string>();

  // metadata
  const [totalMessageCount, setTotalMessageCount] = useState<bigint>(BigInt(-1));
  const [latestBidValue, setLatestBidValue] = useState<bigint>();
  const [latestTimestamp, setLatestTimestamp] = useState<bigint>();
  const [bidResetDuration, setBidResetDuration] = useState<bigint>();

  // visited cookie
  const [cookies, setCookie, removeCookie] = useCookies(['has-visited']);

  useEffect(() => {
    if (cookies['has-visited'] === undefined) {
      setShowAboutModal(true);
      setCookie('has-visited', true);
    }
  }, [cookies]);

  function isValidChain() {
    return chains.map((c: any) => c.id).includes(chain?.id || -1);
  }

  const { data: balance } = useBalance({
    address: chainIdToContractAddress.get(chain?.id || -1)
  })

  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (address === contractOwner) {
      setUserIsOwner(true);
    } else {
      setUserIsOwner(false);
    }
  }, [address, contractOwner]);

  // Get owner
  useContractRead({
    address: chainIdToContractAddress.get(chain?.id || -1),
    abi,
    functionName: 'owner',
    watch: false,
    enabled: isConnected && isValidChain(),
    onSuccess: (data: string) => {
      setContractOwner(data);
    }
  });
  
  useEffect(() => {
    setMinBidInEth(undefined); // Hide table on chain switch
    setLatestTimestamp(BigInt(0)); // Forces refresh on re-login
    console.log(`Contract: ${chainIdToContractAddress.get(chain?.id || -1)}`);
  }, [chain]);

  // watch message count
  const { data: metadata, error: metadataReadError } = useContractRead({
    address: chainIdToContractAddress.get(chain?.id || -1),
    abi,
    functionName: 'getMetadata',
    watch: true,
  });

  useEffect(() => {
    if (metadata) {
      console.log('Got metadata');
      setTotalMessageCount(metadata[0] as bigint);
      setLatestBidValue(metadata[1] as bigint);
      setLatestTimestamp(metadata[2] as bigint);
      setBidResetDuration(metadata[3] as bigint);
      setMinBidIncrement(metadata[4] as bigint);
    }
    if (metadataReadError) {
      console.log('metadataReadError');
      console.log(metadataReadError.cause);
      console.log(metadataReadError.message);
      console.log(metadataReadError.name);
      console.log(metadataReadError.stack);
    }
  }, [metadata, metadataReadError])

  useEffect(() => {
    if (bidResetDuration !== undefined && minBidIncrement !== undefined && latestBidValue !== undefined && latestTimestamp !== undefined) {
      const millis: number = Number((latestTimestamp + bidResetDuration) * BigInt(1000));
      const _bidResetDate = new Date(millis); // X hours after most recent message
      // const bidResetDate = new Date(new Date().getTime() + 10000); // TEST RESET BID IN 10 SECONDS
      setBidResetDate(_bidResetDate);
      // set current min bid
      let timeoutId: number;
      const now = new Date();
      const smallestMinBidInEther = formatEther(minBidIncrement);
      if (_bidResetDate < now) {
        // bid has already reset
        setMinBidInEth(smallestMinBidInEther);
      } else {
        // min bid is latest bid
        setMinBidInEth(formatEther((latestBidValue + minBidIncrement)));
        // reset timeout at reset date
        timeoutId = window.setTimeout(() => {
          setMinBidInEth(smallestMinBidInEther);
        }, _bidResetDate.getTime() - now.getTime());
      }
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      }
    }
  }, [bidResetDuration, minBidIncrement, latestBidValue, latestTimestamp]);

  // get username
  const { refetch: refetchUsername } = useContractRead({
    address: chainIdToContractAddress.get(chain?.id || -1),
    abi,
    functionName: 'addressToUsername',
    args: [address],
    watch: false,
    enabled: isConnected && isValidChain(),
    onSuccess: (data: string) => {
      setUsername(data);
    }
  });

  const { config } = usePrepareContractWrite({
    address: chainIdToContractAddress.get(chain?.id || -1),
    abi,
    functionName: 'withdraw',
    enabled: isConnected && userIsOwner && balance !== undefined && balance.value > 0,
  });

  const { write: withdrawWrite } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      console.log('Withdraw successful', data);
    },
    onError: (err) => {
      console.error('Withdraw failed', err);
    }
  })

  // Update post bid any time min bid updates, if post bid is too small
  useEffect(() => {
    if (minBidInEth) {
      setPostBid(minBidInEth);
    }
  }, [minBidInEth]);

  // function fetchPrices() {
  //   const chainCurrency = 'matic-network'; // TODO get this from chain
  //   fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${chainCurrency}&vs_currencies=${supportedVsCurrencies.join(',')}`).then((res) => {
  //     return res.json();
  //   })
  //   .then((json) => {
  //     console.log(json[chainCurrency]);
  //     setWorldCurrencyToCoinPrice(json[chainCurrency]);
  //   });
  // }

  // useEffect(() => {
  //   fetchPrices();
  //   const intervalId = window.setInterval(() => {
  //     fetchPrices();
  //   }, 600000); // fetch prices every 10 minutes
  //   return () => clearInterval(intervalId);
  // }, []);

  function renderMainPanel(): JSX.Element {
    const elements: JSX.Element[] = [];
    if (isValidChain()) {
      if (minBidInEth) {
        // show table if we have bid data
        elements.push(
          <div style={{ display: 'flex', marginBottom: '0.5rem', justifyContent: 'space-around', alignItems: 'center' }} key="table">
            <div style={{ textAlign: 'center', flex: '1' }}>
              <div style={{ color: 'var(--bs-light)', fontSize: '1.1rem' }}>Minimum bid</div>
              <div>{minBidInEth} {chain?.nativeCurrency.symbol}</div>
              {/* <div className="text-muted" style={{ fontSize: '14px' }}>0.001 USD</div> */}
            </div>
            <div style={{ textAlign: 'center', flex: '1' }}>
              <Button variant="outline-success" size="lg" onClick={() => setShowPostModal(true)}>
                <i className="bi bi-pen" />
                <span className="bidboard-button-text">
                {' '}
                  Create Post
                </span>
              </Button>
            </div>
            <div style={{ textAlign: 'center', flex: '1' }}>
              <div style={{ color: 'var(--bs-light)', fontSize: '1.1rem'  }}>Bid reset</div>
              {bidResetDate ? (
                <ReactTimeAgo date={bidResetDate} timeStyle="round" />
              ) : (
                <>Unknown</>
              )}
            </div>

          </div>
        );
      } else {
        // spinner while waiting for bid data
        elements.push(
          <div style={{ textAlign: 'center', paddingTop: '1rem'}} key="spinner">
            <Spinner />
          </div>
        );
      }
      elements.push(
        <MessageList key="message-list"
          totalMessageCount={totalMessageCount}
          setTotalMessageCount={setTotalMessageCount}
        />
      );
    } else {
      elements.push(
        <div style={{ textAlign: 'center'}} key="network-error">
          <Web3NetworkSwitch />
          <h6 style={{ paddingTop: '0.5rem' }}>Network not supported.</h6>
          <h6>Please switch to one of the following networks:</h6>
          {chains.map((c: any) => <div key={`network-${c.id}`}>{c.name}</div>)}
        </div>
      );
    }
    return <>{elements}</>;
  }

  return (
    <AppContext.Provider value={{ 
      minBidIncrement,
      refreshMessages,
      setRefreshMessages,
      refetchUsername,
    }}>
      <div className="wrapper">
        <Navbar 
          bg="dark"
          // sticky="top"
          style={{borderBottom: 'solid gray 1px'}}
          expand="lg"
        >
          <Container fluid>
            <Navbar.Brand>
              {/* <Hamster width='24px' /> */}
              {/* <img src='https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/svg/1f37a.svg' width='24px' /> */}
              <Dropdown>
                <Dropdown.Toggle 
                  as="div"
                  style={{ 
                    fontSize: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Hamster width='24px' />
                  &nbsp;
                  <span>
                    BidBoard
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowAboutModal(true)}>
                    <i className="bi bi-info-circle" />
                    &nbsp;
                    About
                  </Dropdown.Item>
                  {/* <Dropdown.Item>
                    <i className="bi bi-gear" />
                    &nbsp;
                    Settings
                  </Dropdown.Item> */}
                  {isConnected ? (
                    <Dropdown.Item onClick={() => setShowRegisterModal(true)}>
                      <i className="bi bi-person" />
                      &nbsp;
                      {username ? (
                        <span>Registered as {username}</span>
                      ) : (
                        <span>Register</span>
                      )}
                    </Dropdown.Item>
                  ) : (null)}
                  {isConnected && userIsOwner ? (
                    <>
                    <Dropdown.Item onClick={() => withdrawWrite?.()}>
                      <i className="bi bi-piggy-bank" />
                      {' '}
                      Withdraw {balance?.formatted} {chain?.nativeCurrency.symbol}
                    </Dropdown.Item>
                    {/* <Dropdown.Item onClick={() => setDebugMode(!debugMode)}>
                      <i className="bi bi-bug" />
                      {' '}
                      Toggle Debug ({debugMode ? (<>On</>) : (<>Off</>)})
                    </Dropdown.Item> */}
                    </>
                  ) : ( null )}
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Brand>
            <div style={{
              display: 'flex',
              gap: '4px',
            }}>
              <>
                <Web3Button 
                  // icon="hide"
                  // balance="show"
                />
              </>
            </div>
          </Container>
        </Navbar>
        <Container style={{ maxWidth: '640px', marginTop: '0.5rem', marginBottom: '2rem' }}>
          {isConnected ? (
            renderMainPanel()
          ) : (
            <h6 style={{ textAlign: 'center', paddingTop: '1rem'}}>Please connect wallet to continue.</h6>
          )}
        </Container>
      </div>
      <CreatePostModal
        show={showPostModal}
        setShow={setShowPostModal}
        postBid={postBid}
        setPostBid={setPostBid}
        minBidInEth={minBidInEth}
        worldCurrencyToCoinPrice={worldCurrencyToCoinPrice}
      />
      <RegisterModal 
        show={showRegisterModal}
        setShow={setShowRegisterModal}
      />
      <AboutModal
        show={showAboutModal}
        setShow={setShowAboutModal}
      />
    </AppContext.Provider>
  );
}

export default App;
