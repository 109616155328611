import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { abi, chainIdToContractAddress } from "../../ethData/contract";
import AwaitingConfirmations from "./helpers/AwaitingConfirmations";
import ValidationErrors from "./helpers/ValidationErrors";
import { AppContext } from "../../App";

interface RegisterModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RegisterModal({ show, setShow }: RegisterModalProps) {
  const { setRefreshMessages, refetchUsername } = useContext(AppContext);

  const { chain } = useNetwork(); // connected chain, allowed chains
  const [username, setUsername] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);

  const [takenUsernames, setTakenUsernames] = useState<string[]>([]);

  /**
   * SEND POST
   */
  const { config } = usePrepareContractWrite({
    address: chainIdToContractAddress.get(chain?.id || -1),
    abi,
    functionName: 'register',
    enabled: true,
    args: [username],
    onError: (err) => {
      if (err.message.indexOf('Username already taken') > 0) {
        if (!takenUsernames.includes(username)) {
          setTakenUsernames([...takenUsernames, username]);
        }
      }
    }
  });

  const { write: registerWrite, isLoading: awaitingWalletConfirmation, data: contractWriteData } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      console.log('Post send successful', JSON.stringify(data));
    },
    onError: (err) => {
      console.error('Failed to send post', err);
    }
  });

  const { isLoading: awaitingChainConfirmation } = useWaitForTransaction({
    hash: contractWriteData?.hash,
    onSuccess: () => {
      setShow(false);
      setUsername('');
      setRefreshMessages?.(true);
      refetchUsername?.();
    }
  });
  /**
   * END SEND POST
   */

  // form validation
  useEffect(() => {
    const _validationErrors: string[] = [];
    if (username.length === 0) {
      _validationErrors.push('Username cannot be empty.');
    }
    if (takenUsernames.includes(username)) {
      _validationErrors.push('Username already taken.');
    }
    setValidationErrors(_validationErrors);
  }, [username, takenUsernames]);

  useEffect(() => {
    if (!show) {
      setShowValidationErrors(false);
    }
  }, [show]);

  return (
    <Modal className="bidboard-modal" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Register Username</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Control type="text" value={username} maxLength={16} placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
            {showValidationErrors ? (
              <ValidationErrors validationErrors={validationErrors} />
            ) : (null) }
          </Form.Group>
        </Form>
        <AwaitingConfirmations
          awaitingChainConfirmation={awaitingChainConfirmation}
          awaitingWalletConfirmation={awaitingWalletConfirmation}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => setShow(false)} disabled={awaitingWalletConfirmation || awaitingChainConfirmation}>
          Cancel
        </Button>
        <Button
          variant="outline-success"
          onClick={() => {
            if (validationErrors.length > 0) {
              setShowValidationErrors(true)
            } else {
              registerWrite?.();
            }
          }}
          disabled={awaitingWalletConfirmation || awaitingChainConfirmation }
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}