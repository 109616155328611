interface ValidationErrorsProps {
  validationErrors: string[];
}

export default function ValidationErrors({ validationErrors }: ValidationErrorsProps) {
  return validationErrors.length > 0 ? (
    <div style={{ paddingTop: '1rem' }}>
      {validationErrors.map((validationError) => (
        <div key={validationError}>
          <small style={{ color: 'var(--bs-danger-text-emphasis)' }}>{validationError}</small>
        </div>
      ))}
    </div>
  ) : ( null );
}