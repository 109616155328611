import { useContext, useEffect, useState } from 'react';
import { Button, Col, Collapse, Spinner } from "react-bootstrap";
import { useContractRead, useContractReads, useNetwork } from "wagmi";
import { AppContext } from "../App";
import { abi, chainIdToContractAddress } from "../ethData/contract";

import Message from '../types/Message';
import MessageComponent from './MessageComponent';

import './MessageListComponent.css';

export default function MessageListComponent (
  { totalMessageCount, setTotalMessageCount }:
  { 
    totalMessageCount: bigint,
    setTotalMessageCount: React.Dispatch<React.SetStateAction<bigint>>,
  }
) {
  const { refreshMessages, setRefreshMessages } = useContext(AppContext);
  const { chain, chains } = useNetwork(); // connected chain, allowed chains
  const [messages, setMessages] = useState<Message[]>([]);
  const [indexesToFetch, setIndexesToFetch] = useState<bigint[]>();

  // initial fetch
  useEffect(() => {
    if (messages.length === 0 && totalMessageCount > 0) {
      setIndexesToFetch([totalMessageCount - BigInt(1)]);
    }
  }, [totalMessageCount]);

  // get messages
  const { isFetching, refetch } = useContractReads({
    contracts: indexesToFetch?.map((index) => ({
      address: chainIdToContractAddress.get(chain?.id || -1),
      abi,
      functionName: 'getMessage',
      args: [index]
    })),
    enabled: false,
    cacheTime: 3600000, // 1 hour cache
    onSuccess: (data: any[]) => {
      const updatedMessages = 
      [
        ...messages.filter(m => !indexesToFetch!.includes(BigInt(m.index))), // need to fetch updated version sometimes. don't duplicate
        ...data.map((dataObj: any, retIndex) => {
          const val: any[] = dataObj.result;
          const msg = val[0];
          const username = val[1];
          const initialReplyCount = Number(val[2]);
          return {
            index: indexesToFetch![retIndex], // DANGER -- indexesToFetch could have changed before onSuccess is called
            text: msg.text,
            poster: {
              address: msg.poster,
              username,
            },
            timestamp: Number(msg.timestamp),
            bidValue: msg.bidValue,
            initialReplyCount,
            tips: val[3],
          };
        })
      ];
      updatedMessages.sort((m1, m2) => Number(m2.index - m1.index)); // sort in reverse index order
      setMessages(updatedMessages);
      setIndexesToFetch([]);
    }
  });

  // respond to refresh messages flag
  useEffect(() => {
    if (refreshMessages) {
      setIndexesToFetch(messages.map(m => m.index));
      //setMessages([]);
      setRefreshMessages?.(false);
    }
  }, [refreshMessages]);
  
  // chain switch
  useEffect(() => {
    if (chain) {
      console.log(`Chain switched (${chain?.name}), resetting messages`);
      setMessages([]); // clear state
      setTotalMessageCount(BigInt(-1)); // Force message pull
    }
  }, [chain]);

  useEffect(() => {
    if (indexesToFetch && indexesToFetch.length > 0) {
      refetch();
    }
  }, [indexesToFetch]);

  // Sets indexes to display to include one more post, triggering fetch
  function fetchNextPost() {
    setIndexesToFetch([ messages[0].index + BigInt(1) ]);
  }

  // Helper methods for fetching state
  function isFetchingPreviousPost() {
    return isFetching && 
      indexesToFetch && indexesToFetch.length == 1 &&
      messages[messages.length - 1].index > indexesToFetch[0];
  }

  function isFetchingNewPosts() {
    return isFetching && indexesToFetch && messages[0].index < indexesToFetch[0];
  }

  // "Show previous post" etc.
  function renderFooter(): JSX.Element {
    if (messages.length > 0) {
      if (messages[messages.length - 1].index > 0) {
        return (
          <Col className="text-center">
            <Button variant='outline-success' size='sm' 
              style={{ width: '100%' }}
              onClick={() => {
                setIndexesToFetch([messages[messages.length - 1].index - BigInt(1)]);
              }} 
              disabled={isFetchingPreviousPost()}
            >
              {isFetchingPreviousPost() ? (
                <>
                  <Spinner size="sm"/>
                  &nbsp;
                  Loading previous post
                </>
              ) : (
                <>Show previous post</>
              )}
            </Button>
          </Col>
        );
      } else {
        return (
          <Col className="text-center text-muted">
            No more posts.
          </Col>
        )
      }
    } else {
      return <></>;
    }
  }

  function renderList(): JSX.Element {
    // listItems.length !== 0 ? listItems : (<Spinner />)
    const listItems = messages.map((message) => (
      <Collapse appear in key={Number(message.index)}>
        <div>
          <MessageComponent
            message={message}
          />
        </div>
      </Collapse>
    ));
    if (listItems.length !== 0) {
      return <>{listItems}</>;
    }
    if (totalMessageCount > 0) {
      return (
        <div style={{ textAlign: 'center', paddingTop: '1rem'}} key="spinner">
          <Spinner />
        </div>
      );
    } else {
      return <></>;
    }
  }

  
  return (
    <>
      {messages.length > 0 && messages[0].index < totalMessageCount - BigInt(1) ? (
        <Col className="text-center mb-2">
          <Button variant='outline-success' size='sm' 
            onClick={fetchNextPost} 
            disabled={isFetchingNewPosts()}
            style={{ width: '100%' }}
          >
            {isFetchingNewPosts() ? (
              <>
                <Spinner size="sm"/>
                &nbsp;
                <>Loading next post</>
              </>
            ) : ( 
              <>Show next post</>
            )}
          </Button>
        </Col>
      ) : ( null )}
      {renderList()}
      {renderFooter()}
    </>
  );
}
