import { useContext, useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Form, InputGroup, Modal } from "react-bootstrap";
import { useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { abi, chainIdToContractAddress } from "../../ethData/contract";
import AwaitingConfirmations from "./helpers/AwaitingConfirmations";
import ValidationErrors from "./helpers/ValidationErrors";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { AppContext } from "../../App";
import { formatEther, parseEther } from "viem";

interface CreatePostModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  postBid?: string;
  setPostBid: React.Dispatch<React.SetStateAction<string | undefined>>;
  minBidInEth?: string;
  worldCurrencyToCoinPrice?: { [x:string]: number };
}

export default function CreatePostModal({ show, setShow, postBid, setPostBid, minBidInEth, worldCurrencyToCoinPrice }: CreatePostModalProps) {
  const { chain } = useNetwork(); // connected chain, allowed chains
  const [postText, setPostText] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);
  const { minBidIncrement } = useContext(AppContext);

  /**
   * SEND POST
   */
  const { config } = usePrepareContractWrite({
    address: chainIdToContractAddress.get(chain?.id || -1),
    abi,
    functionName: 'setMessage',
    enabled: show && parseEther(postBid || '0') >= parseEther(minBidInEth || '0'), // prepare contract while modal is open
    args: [postText],
    value: parseEther(postBid || '0'),
  });

  const { write: setMessageWrite, isLoading: awaitingWalletConfirmation, data: contractWriteData } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      console.log('Post send successful', JSON.stringify(data));
    },
    onError: (err) => {
      console.error('Failed to send post', err);
    }
  });

  const { isLoading: awaitingChainConfirmation } = useWaitForTransaction({
    hash: contractWriteData?.hash,
    onSuccess: () => {
      setShow(false);
      setPostText('');
    }
  });
  /**
   * END SEND POST
   */

  // form validation
  useEffect(() => {
    const _validationErrors: string[] = [];
    if (postText.length === 0) {
      _validationErrors.push('Post cannot be empty.');
    }
    if (parseEther(postBid || '0') < parseEther(minBidInEth || '0')) {
      _validationErrors.push('Bid is too small.');
    }
    setValidationErrors(_validationErrors);
  }, [postText, postBid]);

  useEffect(() => {
    if (!show) {
      setShowValidationErrors(false);
    }
  }, [show]);

  return (
    <Modal className="bidboard-modal" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Create Post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-2">
            <Form.Control size="lg" as="textarea" rows={4} placeholder="Enter text here" value={postText} onChange={(e) => setPostText(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Bid amount (minimum {minBidInEth} {chain?.nativeCurrency.symbol})</Form.Label>
            <InputGroup>
              <Form.Control 
                size="lg" 
                type="number" 
                placeholder="Enter bid here" 
                min={minBidInEth} 
                value={postBid} 
                step={formatEther(minBidIncrement || BigInt(1))}
                onChange={(e) => setPostBid(e.target.value)}
              />
              <InputGroup.Text>{chain?.nativeCurrency.symbol}</InputGroup.Text>
            </InputGroup>
            {showValidationErrors ? (
              <ValidationErrors validationErrors={validationErrors} />
            ) : (null) }
          </Form.Group>
        </Form>
        {/* <div style={{ display: 'flex' }}> TODO FIGURE OUT WHERE TO PUT THIS
          <Form.Select
            value="usd"
            style={{ maxWidth: '90px' }}
          >
            {worldCurrencyToCoinPrice ? Object.entries(worldCurrencyToCoinPrice).map(([symbol, price]) => (
              <option value={symbol}>{symbol.toUpperCase()}</option>
            )): 
              (null)
            }
          </Form.Select>
        </div> */}
        <AwaitingConfirmations
          awaitingChainConfirmation={awaitingChainConfirmation}
          awaitingWalletConfirmation={awaitingWalletConfirmation}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => setShow(false)} disabled={awaitingWalletConfirmation || awaitingChainConfirmation}>
          Cancel
        </Button>
        <Button
          variant="outline-success"
          onClick={() => {
            if (validationErrors.length > 0) {
              console.log(validationErrors);
              setShowValidationErrors(true)
            } else {
              setMessageWrite?.();
            }
          }}
          disabled={awaitingWalletConfirmation || awaitingChainConfirmation }
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}