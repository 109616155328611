import { useContext, useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Form, InputGroup, Modal } from "react-bootstrap";
import { useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { abi, chainIdToContractAddress } from "../../ethData/contract";
import AwaitingConfirmations from "./helpers/AwaitingConfirmations";
import ValidationErrors from "./helpers/ValidationErrors";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { AppContext } from "../../App";
import { ReactComponent as Hamster } from '../../hamster.svg';


interface AboutModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  minBidInEth?: string;
}

export default function AboutModal({ show, setShow, minBidInEth }: AboutModalProps) {
  const { chain } = useNetwork(); // connected chain, allowed chains
  const [postText, setPostText] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);
  const { minBidIncrement } = useContext(AppContext);


  return (
    <Modal className="bidboard-modal" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title style={{ display: 'flex', alignItems: 'center', color: 'var(--bs-light)' }}>
          Welcome to BidBoard!
          &nbsp;
          <Hamster width="24px"/></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 style={{ color: 'var(--bs-teal)'}}>What is this?</h2>
        <p>BidBoard is an experimental web3 message board where the front page post is written by the highest bidder.</p>
        <p>When a post stays at the top for 24 hours without getting outbid, the minimum bid value resets to 0.1 MATIC.</p>
        <p>Reply to any post for only a transaction fee. Posts and replies are stored permanently on the blockchain.</p>
        <p>Connect to Polygon Mainnet with any web3 wallet to read and post.</p>
        <h2 style={{ color: 'var(--bs-teal)'}}>Why should I pay to post here?</h2>
        <ul>
          <li>You have something to say that's worth some MATIC.</li>
          <li>You're excited about the possibilities of a permanent, censorship-resistant social platform and want to document your enthusiasm on the nearest blockchain.</li>
          <li>You're tired of other web3 projects trying to sell you NFTs and useless tokens.</li>
          <li>It's neat.</li>
        </ul>
        <h2 style={{ color: 'var(--bs-teal)'}}>What else can I do?</h2>
        <p><span style={{ color: 'var(--bs-light)' }}>Register</span>: Reserve a unique username associated with your wallet address. Your username will appear next to your posts and replies.</p>
        <p><span style={{ color: 'var(--bs-light)' }}>Tip</span>: Like a post? Send a tip in a reply or anonymously. You can tip any amount of MATIC and it goes directly to the poster. The number and total value of tips are displayed at the bottom of a post.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}