import { useEffect, useState } from "react";
import { Collapse, Fade, OverlayTrigger, Popover } from "react-bootstrap";
import { useContractRead, useNetwork } from "wagmi";
import { abi, chainIdToContractAddress } from '../ethData/contract';
import { Poster } from "../types/Poster";


interface PosterTextProps {
  poster: Poster;
}

function abbreviateAddress(address: string): string {
  return `${address.substring(0, 4)}...${address.substring(address.length - 4)}`;
}

export default function PosterText({ poster }: PosterTextProps) {
  return (
    <>
      
      <span>
        <span style={{ color: 'var(--bs-light)'}}>Poster:</span>
        &nbsp;
          {poster.username.length > 0 ? (
            <span style={{ color: 'var(--bs-teal)' }}>{poster.username} </span>
          ) : (null)}
        <OverlayTrigger
          trigger="click"
          placement="auto"
          rootClose={true}
          overlay={
            <Popover id="address-popover" className="bidboard-popover" style={{ maxWidth: '100%'}}>
              <Popover.Header as="h3">
                {poster.username.length > 0 ? (<span style={{ color: 'var(--bs-teal)'}}>{poster.username}</span>) : "User not registered"}
              </Popover.Header>
              <Popover.Body>
                {poster.address}
              </Popover.Body>
            </Popover>
          }
        >
          <span>
            <span className="link-light" style={{ textDecoration: 'underline', cursor: 'pointer' }}>{abbreviateAddress(poster.address)}</span>
          </span>
        </OverlayTrigger>
      </span>
      
    </>
  )
}