import { Collapse, Spinner } from "react-bootstrap";

interface AwaitingConfirmationsProps {
  awaitingWalletConfirmation: boolean;
  awaitingChainConfirmation: boolean;
}

export default function AwaitingConfirmations({ awaitingWalletConfirmation, awaitingChainConfirmation }: AwaitingConfirmationsProps) {
  return (
    <Collapse appear in>
      <div style={{ textAlign: 'center' }}>
        {awaitingWalletConfirmation ? (
          <div style={{ paddingTop: '16px' }}>
            <Spinner size="sm" /> Awaiting wallet confirmation...
          </div>
        ) : ( null )}
        {awaitingChainConfirmation ? (
          <div style={{ paddingTop: '16px' }}>
            <Spinner size="sm" /> Awaiting transaction confirmation...
          </div>
        ) : ( null )}
      </div>
    </Collapse>
  )
}