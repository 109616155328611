import { useContext, useEffect, useState } from "react";
import { Button, Collapse, Form, InputGroup, Modal } from "react-bootstrap";
import { useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { abi, chainIdToContractAddress } from "../../ethData/contract";
import AwaitingConfirmations from "./helpers/AwaitingConfirmations";
import ValidationErrors from "./helpers/ValidationErrors";
import { AppContext } from "../../App";
import { formatEther, parseEther } from "viem";

interface CreatePostReplyModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  worldCurrencyToCoinPrice?: { [x:string]: number };
  messageIndex: bigint;
}

export default function CreatePostReplyModal ({ show, setShow, worldCurrencyToCoinPrice, messageIndex }: CreatePostReplyModalProps) {
  const { minBidIncrement, setRefreshMessages } = useContext(AppContext);

  const { chain } = useNetwork(); // connected chain, allowed chains
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>('');
  const [replyTip, setReplyTip] = useState<string>('0');
  
  /**
   * SEND REPLY
   */
  const { config, error } = usePrepareContractWrite({
    address: chainIdToContractAddress.get(chain?.id || -1),
    abi,
    functionName: 'sendReply',
    enabled: show, // prepare contract while modal is open
    args: [messageIndex, replyText],
    value: parseEther(replyTip || '0'),
  });

  const { write: sendReplyWrite, isLoading: awaitingWalletConfirmation, isSuccess, data: contractWriteData } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      console.log('Post send successful', JSON.stringify(data));
    },
    onError: (err) => {
      console.error('Failed to send post', err);
    }
  });

  const { isLoading: awaitingChainConfirmation } = useWaitForTransaction({
    hash: contractWriteData?.hash,
    onSuccess: () => {
      setShow(false);
      setReplyText('');
      setReplyTip('0');
      if (parseEther(replyTip || '0') > 0) { // refresh messages if user tipped
        setRefreshMessages?.(true);
      }
    }
  });
  /**
   * END SEND REPLY
   */

  // form validation
  useEffect(() => {
    const _validationErrors: string[] = [];
    if (replyText.length === 0) {
      _validationErrors.push('Reply cannot be empty.');
    }
    if (parseEther(replyTip || '0') < (parseEther('0'))) {
      _validationErrors.push('Tip cannot be negative.');
    }
    setValidationErrors(_validationErrors);
  }, [replyText, replyTip]);

  useEffect(() => {
    if (!show) {
      setShowValidationErrors(false);
    }
  }, [show]);

  return (
    <Modal className="bidboard-modal" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Post Reply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2">
              <Form.Control size="lg" as="textarea" rows={4} placeholder="Enter text here" value={replyText} onChange={(e) => setReplyText(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tip amount (optional)</Form.Label>
              <InputGroup>
                <Form.Control 
                  size="lg" 
                  type="number" 
                  placeholder="Enter tip here" 
                  min={0} 
                  value={replyTip} 
                  step={formatEther(minBidIncrement || BigInt(1))}
                  onChange={(e) => setReplyTip(e.target.value)}
                />
                <InputGroup.Text>{chain?.nativeCurrency.symbol}</InputGroup.Text>
              </InputGroup>
              {showValidationErrors ? (
                <ValidationErrors validationErrors={validationErrors} />
              ) : (null) }
            </Form.Group>
          </Form>
          <AwaitingConfirmations
            awaitingChainConfirmation={awaitingChainConfirmation}
            awaitingWalletConfirmation={awaitingWalletConfirmation}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShow(false)} disabled={awaitingWalletConfirmation || awaitingChainConfirmation}>
            Cancel
          </Button>
          <Button 
            variant="outline-success"
            onClick={() => {
              if (validationErrors.length > 0) {
                console.log(validationErrors);
                setShowValidationErrors(true)
              } else {
                sendReplyWrite?.();
              }
            }}
            disabled={awaitingWalletConfirmation || awaitingChainConfirmation }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
  );
}