import Linkify from 'linkify-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, Button, Card, Collapse, Form, InputGroup, ListGroup, Modal, Overlay, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import ReactTimeAgo from 'react-time-ago';
import { useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { AppContext } from '../App';
import { abi, chainIdToContractAddress } from '../ethData/contract';
import Message from '../types/Message';
import Reply from '../types/Reply';
import CreatePostReplyModal from './modals/CreatePostReplyModal';
import SendTipModal from './modals/SendTipModal';
import PosterText from './PosterText';
import { formatEther } from 'viem';

const PAGE_SIZE = 5; // max number of replies to load at once

/**
 * @param start 
 * @param end 
* @returns 
*/
function pythonicRange(start: number, end: number): number[] {
  let ret: number[] = [];
  for (let i=start; i<end; i++) {
    ret.push(i);
  }
  return ret;
}

export default function MessageComponent (
  { message }:
  { 
    message: Message
  }
) {
  const [totalReplyCount, setTotalReplyCount] = useState<number>(message.initialReplyCount);
  const [repliesDisplayCount, setRepliesDisplayCount] = useState<number>(0);

  const [replies, setReplies] = useState<Reply[]>([]);
  const [showReplyModal, setShowReplyModal] = useState<boolean>(false);
  const [showSendTipModal, setShowSendTipModal] = useState<boolean>(false);

  const { chain, chains } = useNetwork(); // connected chain, allowed chains

  /**
   * READ REPLIES
   */
  // load replies in range [replies.length, repliesDisplayCount)
  const { isFetching: isFetchingMore, refetch: fetchMoreReplies } = useContractReads({
    contracts: pythonicRange(replies.length, repliesDisplayCount).map((replyIndex) => ({
      address: chainIdToContractAddress.get(chain?.id || -1),
      abi,
      functionName: 'getReply',
      args: [message.index, replyIndex]
    })),
    enabled: false,
    cacheTime: 600000, // 10 minute cache
    onSuccess: (data: any[]) => {
      setReplies([
        ...replies,
        ...data.map((dataObj: any) => {
          const val: any[] = dataObj.result;
          const reply = val[0];
          const username = val[1];
          return {
            text: reply.text,
            poster: {
              address: reply.poster,
              username,
            },
            timestamp: reply.timestamp,
            tip: reply.tip,
          };
        })
      ]);
    }
  });

  // fetch all replies
  const { isFetching: isFetchingAll, refetch: refetchAllReplies } = useContractReads({
    contracts: pythonicRange(0, repliesDisplayCount).map((replyIndex) => ({
      address: chainIdToContractAddress.get(chain?.id || -1),
      abi,
      functionName: 'getReply',
      args: [message.index, replyIndex]
    })),
    enabled: false,
    cacheTime: 600000, // 10 minute cache
    onSuccess: (data: any[]) => {
      setReplies(data.map((dataObj: any) => {
        const val: any[] = dataObj.result;
        const reply = val[0];
        const username = val[1];
        return {
          text: reply.text,
          poster: {
            address: reply.poster,
            username,
          },
          timestamp: reply.timestamp,
          tip: reply.tip,
        };
      })
      );
    }
  });


  // watch reply count
  useContractRead({
    address: chainIdToContractAddress.get(chain?.id || -1),
    abi,
    functionName: 'getReplyCount',
    args: [message.index],
    watch: true,
    onSuccess: (data: bigint) => {
      const _totalReplyCount = Number(data);
      setTotalReplyCount(_totalReplyCount);
      if (_totalReplyCount > 0 && repliesDisplayCount == 0) {
        // load first page of replies automatically
        setRepliesDisplayCount(_totalReplyCount > PAGE_SIZE ? PAGE_SIZE : _totalReplyCount);
      }
    }
  });

  // refetch replies if display count changes
  useEffect(() => {
    if (repliesDisplayCount > 0) {
      fetchMoreReplies();
    }
  }, [repliesDisplayCount]);

  // refetch replies if message changes (happens on refresh--username change or tip change)
  useEffect(() => {
    if (repliesDisplayCount > 0) {
      refetchAllReplies();
    }
  }, [message]);

  // load up to PAGE_SIZE more replies
  function showMoreReplies() {
    const moreReplies = totalReplyCount - repliesDisplayCount;
    setRepliesDisplayCount(moreReplies > PAGE_SIZE ? repliesDisplayCount + PAGE_SIZE : totalReplyCount);
  }

  const tooltipTarget = useRef(null);

  const dateObj = new Date(message.timestamp * 1000);
  const dateString = `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString()}`;
  return (
    <>
      <Card 
        key={message.timestamp} 
        style={totalReplyCount > 0 ? { borderBottomLeftRadius: '0', borderBottomRightRadius: '0', borderBottom: 'none' } : {}}
        className={totalReplyCount === 0 ? 'mb-2' : ''}
      >
        <Card.Header style={{ fontSize: "16px", padding: "8px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div style={{ color: 'var(--bs-light)'}}>
                {dateString}
              </div>
              <div style={{ fontSize: "14px" }} className="text-muted">
                <ReactTimeAgo date={dateObj} />
              </div>
            </div>
            <div>
              <Button 
                size="sm" 
                variant="outline-light"
                onClick={() => {
                  setShowReplyModal(true);
                }}
              >
                <i className="bi bi-reply-fill" />
              </Button>
              &nbsp;
              <Button 
                size="sm" 
                variant="outline-light"
                onClick={() => {
                  setShowSendTipModal(true);
                }}
              >
                <i className="bi bi-cash-coin" />
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Card.Text style={{ fontSize: "24px", overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }} as="div">
            <div style={{ color: 'var(--bs-light)'}}>
              <Linkify>{message.text}</Linkify>
            </div>
            <div className="bidboard-card-footer">
              <PosterText poster={message.poster} />
              <br />
              <span style={{ color: 'var(--bs-light)'}}>Bid:</span>
              &nbsp;
              <span className="text-muted">{formatEther(message.bidValue)} {chain?.nativeCurrency.symbol}</span>
              <br />
              <span style={{ color: 'var(--bs-light)'}}>Tips:</span>
              &nbsp;
              <span className="text-muted">
                {message.tips.length > 0 ? (
                  <span>
                    <>
                      {`${message.tips.length} `}
                      (
                        {formatEther(message.tips.reduce((a, b) => a + b))}
                        &nbsp;
                        {chain?.nativeCurrency.symbol}
                      )
                    </>
                  </span>
                ) : ('0')}
              </span>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
      {totalReplyCount > 0 ? (
        <Accordion className="mb-2 bidboard-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Replies ({totalReplyCount})</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush">
                {replies.map((reply) =>
                  <ListGroup.Item style={{ padding: '16px' }} key={`${reply.poster}-${reply.timestamp}`}>
                    <div style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap', marginBottom: '0.5rem', color: 'var(--bs-light)' }}>
                      <Linkify>{reply.text}</Linkify>
                    </div>
                    <div className="bidboard-card-footer">
                      <PosterText poster={reply.poster} />
                      <br />
                      {reply.tip && reply.tip > 0 ? (
                        <>
                          <span>
                            <span style={{ color: 'var(--bs-light)'}}>Sent Tip:</span>
                            &nbsp;
                            <>
                              {formatEther(reply.tip)}
                              &nbsp;
                              {chain?.nativeCurrency.symbol}
                            </>
                          </span>
                          <br />
                        </>
                      ) : (null)}
                      <span className="text-muted"><ReactTimeAgo date={new Date(Number(reply.timestamp * BigInt(1000)))} /></span>
                    </div>
                  </ListGroup.Item>
                )}
              </ListGroup>
              {replies.length < totalReplyCount ? (
                <div className="text-center" style={{ margin: '0 0.5rem 0.5rem 0.5rem'}}>
                  <Button 
                    variant='outline-success'
                    size='sm'
                    style={{ width: '100%' }}
                    onClick={() => showMoreReplies()}
                    disabled={isFetchingAll || isFetchingMore}
                  >
                    {isFetchingAll || isFetchingMore ? (
                      <>
                        <Spinner size="sm"/>
                        &nbsp;
                        Loading replies
                      </>
                    ) : (
                      <>Show more replies</>
                    )}
                  </Button>
                </div>
              ) : (
                null
              )}
             
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        null
      )}

      <SendTipModal
        show={showSendTipModal}
        setShow={setShowSendTipModal}
        messageIndex={message.index}
      />

      <CreatePostReplyModal
        show={showReplyModal}
        setShow={setShowReplyModal}
        messageIndex={message.index}
      />
    </>
  );
}