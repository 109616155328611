import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

// WalletConnect
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { optimismGoerli, polygonMumbai, goerli, sepolia, polygon } from 'wagmi/chains';
import { Web3Modal } from '@web3modal/react';

const projectId = process.env.REACT_APP_PROJECT_ID!;
const chains = [
  //polygonMumbai,
  //optimismGoerli,
  polygon
];

const { publicClient } = configureChains(
  chains,
  [w3mProvider({ projectId })],
  // { pollingInterval: 10000, } // in case of rate limiting, break glass
);

const config = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
})
const ethereumClient = new EthereumClient(config, chains);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <WagmiConfig config={config}>
      <App />
    </WagmiConfig>
    <Web3Modal 
      projectId={projectId} 
      ethereumClient={ethereumClient}
      themeMode="dark"
      themeVariables={{
        "--w3m-font-family": "Jura, sans-serif",
        "--w3m-accent-color": "var(--bs-green)",
        "--w3m-background-color": "var(--bs-green)",
      }}
    />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
